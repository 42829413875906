define("discourse/plugins/AsktugPlugin/discourse/routes/discourse-plugin-root", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    controllerName: "dummy",
    // https://github.com/discourse/discourse/commit/48987af324c54b3305b12e302cdb4bdc7901c190
    templateName: 'react-el-wrapper'
  });
});