define("discourse/plugins/AsktugPlugin/discourse/templates/components/react-el", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    
  */
  {
    "id": "wMjzSpWr",
    "block": "[[],[],false,[]]",
    "moduleName": "discourse/plugins/AsktugPlugin/discourse/templates/components/react-el.hbs",
    "isStrictMode": false
  });
});