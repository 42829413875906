define("discourse/plugins/AsktugPlugin/discourse/route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("discourse-plugin-root", {
      path: "/x/*path"
    });
  }
  ;
});